import * as React from "react"
import Head from "../components/Head";
import Layout from "../components/Layout";
import Placeholder from "../components/Placeholder";

const IndexPlaceholderPage = () => {
  return (
    <Layout footer={false} placeholder={true}>
      <Head/>
      <Placeholder/>
    </Layout>
  )
}

export default IndexPlaceholderPage
