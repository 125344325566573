import React from "react";
import Div100vh from 'react-div-100vh'


const Placeholder = ({children}) => {

    return (
        <Div100vh className={"placeholder__outer"}>
            <div className={"placeholder__inner"}>
            <h1 className={"center minion"}>Mehr Infos kommen bald.</h1>
            <div className="separator title"></div>
            <p className={"center museo"}>
                Bis dahin beantworten wir Fragen an <a href="mailto:info@mattheymelchior.at" className="underline">info@mattheymelchior.at</a> sehr gerne persönlich.
            </p>
            </div>
        </Div100vh>
    )
}


export default Placeholder
