import React from 'react';
import { Helmet } from 'react-helmet';


const Head = ({ title}) => {
    return (
        <>
            <Helmet
                defaultTitle="Matthey & Melchior"
                title={title}
                titleTemplate="%s | Matthey & Melchior"
                htmlAttributes={{
                    lang: 'de',
                }}
            >
            </Helmet>
        </>
    )
}

export default Head;
